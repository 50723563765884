import React, {useEffect} from "react"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Logo6 from "../images/30_Years_Seal_Gray_final.png"
import Logo7 from "../images/Years1991_B-252x120.jpeg"
import Logo8 from "../images/Years1992_B-252x120.jpeg"
import Logo9 from "../images/Years1996.jpeg"
import Logo10 from "../images/Years2001.jpeg"
import Logo11 from "../images/Years2006.jpeg"
import Logo12 from "../images/Calendar-2011-15.jpg"
import Logo13 from "../images/Calendar-2016 -20.jpg"
import Logo14 from "../images/Calendar2021.png"

export default function Aboutus() {
  
  useEffect(() => {
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: true, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });
  }, [])
  
  return (
    <div>
      <section className="deals-banner">
        <h1
          className="text-center font-weight-bold mb-0"
          style={{
            color: `#005D9D`,
            fontFamily: `Ubuntu, sans-serif`,
            fontSize: "clamp(1.75em, 5vw, 3em",
            fontWeight: `700`,
          }}>
            Timeline of Christian Blue
        </h1>
      </section>
      <div className="container church-resources pt-5">
        <div className="row justify-content-center mx-0">
          <div className="col-md-8 col-lg-6">
            <div className="church-resources-card">
              <div className="history-img-wrapper">
                <img
                  src={Logo6}
                  alt="Typewriter typing - What's your story?"
                  className="img-fluid"
                />
                {/* <div className="px-4">
                  <h2 className="mb-3 church-resources-head">History</h2>
                  <p className="mb-3 church-resources-details">
                    Our story began in 1991 in Dayton, Ohio
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="timeline">
          <div className="history" data-aos="fade-right" data-aos-duration="1000">
            <h3 className="mb-4">1991 Our Story Begins Dayton OH</h3>
            <img src={Logo7} alt="" className="img-fluid" />
            <p className="mt-4">
              Our story begins in Dayton, Ohio. Three men - a captain in the Air
              Force, a Christian business owner, and a pastor - had a vision to
              start a Christian business direcory, and unite the body of Christ.
              KTW, Inc was established.
              <ul>
                <li>K - Bryan Kelchner</li>
                <li>T - Larry Trimbach</li>
                <li>W - Greg Weis</li>
              </ul>
              Our original Christian telephone directory, named Central &
              Southwest Ohio Christian Directory, was released. It covered the
              metropolitan areas of Cincinnati, Columbus, & Dayton, Ohio.
          </p>
          </div>
          <div className="history" data-aos="fade-left" data-aos-duration="1000">
            <h3 className="mb-4">1992-95 Christian Blue Pages</h3>
            <img src={Logo8} alt="" className="img-fluid" />
            <p className="mt-4">
              <ul>
                <li>
                  The name “Christian Blue Pages” was adopted. The name
                  reflected the blue paper on which the directory was printed.
                </li>
                <li>
                  The new name and logo was first adopted for print directory.
                </li>
                <li>
                  Our one print publication separated into three localized
                  publications: Cincinnati & NKY, Dayton/Miami Valley &
                  Springfield, and Columbus & central Ohio.
                </li>
              </ul>
            </p>
          </div>
          <div className="history" data-aos="fade-right" data-aos-duration="1000">
            <h3 className="mb-4">1996-2000 Christmas Carol Songbook</h3>
            <img src={Logo9} alt="" className="img-fluid" />
            <p className="mt-4">
              <ul>
                <li>
                  Christmas Carol Songbooks first released in Columbus, Dayton &
                  Cincinnati OH
                </li>
                <li>Graphics and sales operations brought in-house.</li>
                <li>
                  ChristianBluePages.com launched, allowing consumers to find
                  Christian owned and operated businesses 24/7 online.
                </li>
                <li>
                  Springfield/Urbana local directory released – separate from
                  the Dayton-Miami Valley print directory.
                </li>
              </ul>
            </p>
          </div>
          <div className="history" data-aos="fade-left" data-aos-duration="1000">
            <h3 className="mb-4"> 2001-2005</h3>
            <img src={Logo10} alt="" className="img-fluid" />
            <p className="mt-4">
              <ul>
                <li>First Northern Kentucky print directory released.</li>
                <li>
                  First Indianapolis print directory & Christmas carol songbook
                  are launched.
                </li>
                <li>Anderson/Muncie print directory launched.</li>
                <li>New logo design unveiled.</li>
                <li>New brand message released – “Get it Out of the Blue”.</li>
                <li>“Get it Out of the Blue” jingle produced.</li>
                <li>Akron/Canton directory debuts.</li>
              </ul>
            </p>
          </div>
          <div className="history" data-aos="fade-right" data-aos-duration="1000">
            <h3 className="mb-4">2006-2010</h3>
            <img src={Logo11} alt="" className="img-fluid" />
            <p className="mt-4">
              <ul>
                <li>15 year anniversary celebrated.</li>
                <li>First Greater Cleveland directory released.</li>
                <li>
                  ChristianBluePages.com revamped - easier to use and greater
                  search capabilities.
                </li>
                <li>Banner ads & coupons added to website.</li>
                <li>
                  Christmas carol songbook renamed to “Christmas Blue Pages”.
                </li>
                <li>
                  Christian Blue moves into realm of social media with Facebook
                  & LinkedIn.
                </li>
                <li>
                  Carol Cincinnati launched – a city-wide Christmas caroling
                  outreach.
                </li>
              </ul>
            </p>
          </div>
          <div className="history" data-aos="fade-left" data-aos-duration="1000">
            <h3 className="mb-4">2011-2015 Android & iPhone Apps Launch</h3>
            <img src={Logo12} alt="" className="img-fluid" />
            <p className="mt-4">
              <ul>
                <li>Our first Android and iPhone apps released. </li>
                <li>
                  Christian Blue celebrates its 20 year anniversary as employees
                  and their families enjoy a 4 day Royal Caribbean cruise to
                  Cozumel, Mexico.
                </li>
                <li>New Christmas website launched – GoCaroling.com</li>
              </ul>
            </p>
          </div>
          <div className="history" data-aos="fade-right" data-aos-duration="1000">
            <h3 className="mb-4">2016-2020</h3>
            <img src={Logo13} alt="" className="img-fluid" />
            <p className="mt-4">
              <ul>
                <li>
                  Christian Blue celebrates 25 years of serving Ohio, Indiana &
                  NKY consumers.
                </li>
                <li>
                  New website TrustBlueReview.com launched... with consumer
                  review tool, events calendar, and more.
                </li>
                <li>
                  Trust Blue Review re-branding for radio and television
                  commercials.
                </li>
                <li>
                  TRUSTY the Lion mascot debuts at “The Fest” Christian music
                  festival in Cleveland.
                </li>
              </ul>
            </p>
          </div>
          <div className="history" data-aos="fade-left" data-aos-duration="1000">
            <h3 className="mb-4">2021 and beyond</h3>
            <img src={Logo14} alt="" className="img-fluid" />
            <p className="mt-4">
              <ul>
                <li>
                  Digital “Flip Page” directories released in all print markets.
                </li>
                <li>30 Year Celebration Event</li>
                <li>New website launched - ChristianBlue.com</li>
                <li>New Christian Blue mobile app released.</li>
                <li>
                  Christian Blue Certified designation for Christian owned &
                  operated businesses unveiled.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}